import { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import FadeIn from 'react-fade-in';



const Story = (props) => {
    useEffect(() => {
        document.documentElement.style.setProperty('--backgroundcolor', 'rgb(0,91,58)');
        console.log('--backgroundcolor');
    }, []);



    return (




        <Container>
            <FadeIn>
                <WelcomeText>
                <br />
                <br />
                    <h1>It is odd to think of something so long ago that lead to the creation of something so obtuse</h1>

                    <br />
                   
                    <h2> It was many moons ago in the dark recess of a college party. Two lads were talking. </h2>

                    <ChatA>Hey for some reason an anniversary of when you started dating is a thing. 
                    <IMGA src="./images/aa.png" /> 
                    </ChatA>

                    <ChatG>What.              ?
                    <IMGG src="./images/ag.png" /> 
                    </ChatG>


                    <ChatA> 
                    I forget mine yesterday and I know you started dating your girl a week later than me..... so heads up!
                    <IMGA src="./images/aa.png" /> 
                    </ChatA>


                    <ChatG>WTF is an anniversary of dating? That's madness/
                    <IMGG src="./images/ag.png" /> 
                    </ChatG>

                    <ChatA>I know, I know...  that's what I thought, then I forgot it.
                    <IMGA src="./images/aa.png" /> 
                    </ChatA>



                    <ChatG>
                        Was it an issue?
                    <IMGG src="./images/ag.png" /> 
                    </ChatG>


                    <ChatA>Huge Issue.  Tears, arguments, the whole thing.
                    <IMGA src="./images/aa.png" /> 
                    </ChatA>



                    <ChatG>
                        Fair enough. Thankyou for the heads up!
                    <IMGG src="./images/ag.png" /> 
                    </ChatG>



                    <br /><br /><br /><br /><br /> <br /><br /><br /><br /><br />
                    <h2>With this noble display of friendship many a romantic evening was saved. </h2>
                    <h2>Thus just as there was a good friend who would remind me of things, so now can you. </h2>
                    <h2>In the form of an elephant named Andrew. </h2>

                    <AndrewStar src="./images/bigelephant.gif" /> 




                </WelcomeText>



            </FadeIn>
        </Container>

    )
};





const Container = styled.div`
    max-width:452px;

    display:flex;
    margin-left:auto;
    margin-right:auto;

    justify-content:center;
    flex-direction:column;
    position:relative;  
    margin-top:0vh;
    margin-bottom:0vh;


    @media (max-width: 768px) {
        padding-left:5vw;
        padding-right: 5vw;
    }


`;



const WelcomeText = styled.div`
    color:white;
    text-align:left;
    margin-top:10vh;
    margin-bottom:10vh;
`

const ChatA = styled.p`
    display:inline-block;
    border-radius: 10px;
    border-bottom-left-radius:0;
    color:#232323;
    text-align:left;
    background-color:rgb(255,180,0);
    margin-top:3vh;
    margin-bottom:3vh;
    padding:16px;
    line-height:24px;
    max-width:60%;
    position:relative;
    margin-left:32px;
    box-shadow:rgba(17,17,17, .1) 4px 4px 4px  ;
`

const IMGA = styled.img`
    height:26px;
    width:auto;
    bottom: -32px;
    left:-32px;
    position:absolute;
`

const ChatG = styled.p`
    display:inline-block;
    border-radius: 10px;
    border-bottom-right-radius:0;
    color:#232323;
    float:right;
    text-align:left;
    background-color:#f9f9f9;
    margin-top:3vh;
    margin-bottom:3vh;
    padding:16px;
    line-height:24px;
    max-width:60%;
    position:relative;
    margin-right:32px;
    box-shadow:rgba(17,17,17, .1) 4px 4px 4px  ;
`

const IMGG = styled.img`
    height:26px;
    width:auto;
    bottom: -32px;
    right:-32px;
    position:absolute;
`





const AndrewStar = styled.img`
    height:auto;
    width:60%;
`


export default Story;



