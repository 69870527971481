import { useEffect } from 'react';
import { Link } from "react-router-dom";
import styled from "styled-components";
import FadeIn from 'react-fade-in';


const Pricing = (props) => {
    useEffect(() => {
        document.documentElement.style.setProperty('--backgroundcolor', 'rgb(133,0,185)');
        console.log('--backgroundcolor');
    }, []);
    return (
        <Container>
            <FadeIn>

            <WelcomeText>
                  <h1>Free to use forever!</h1>
                  <h2>Or untill a confusing event where it get's too popular and I can't afford the servers so I have to make a bold promise to venture capitalists in order to raise money to keep the app working... But that is very unlikely.</h2>
                  <h2>I mean even if that does happen it's likely all the original users will get grandfathered into to some sort of free tier with all the features of the paid plan.</h2>
                  <h2>But again that is very unlikely</h2>

            </WelcomeText>

            </FadeIn>
        </Container>

)};





const Container = styled.div`
max-width:452px;

display:flex;
margin-left:auto;
margin-right:auto;

justify-content:center;
flex-direction:column;
position:relative;  
margin-top:5vh;
margin-bottom:0vh;
background-color:rgb(133,0,185);


@media (max-width: 768px) {

padding-left:5vw;
padding-right: 5vw;
}


`;



const WelcomeText = styled.div`
    color:white;
    text-align:left;
    margin-top:10vh;
    margin-bottom:10vh;
`






export default Pricing;