import { useEffect } from 'react';
import { Link } from "react-router-dom";
import styled from "styled-components";
import FadeIn from 'react-fade-in';


const Demo = (props) => {
    useEffect(() => {
        document.documentElement.style.setProperty('--backgroundcolor', 'rgb(0,0,0)');
        console.log('--backgroundcolor');
    }, []);
    return (
        <Container>


            <UserWelcome>
                Good Morning George,
                Remember Nothing Deserves your fear.

            </UserWelcome>


            <Reminders>
                <Reminder>
               
                    <Rtitle>
                        Interview with BCG
                    </Rtitle>
                    <Rsubtitle>
                        Pay Rent
                    </Rsubtitle>
                    <Rindicators>
                    <Rtime id="time" />
                       <Randrew src="./images/aa2run.gif" />
                    </Rindicators>
                </Reminder>

            
       

                
            </Reminders>



        </Container>

    )
};





const Container = styled.div`
        max-width:452px;

        display:flex;
        margin-left:auto;
        margin-right:auto;

        justify-content:center;
        flex-direction:column;
        position:relative;  
        margin-top:0vh;
        margin-bottom:0vh;



        @media (max-width: 768px) {

        padding-left:5vw;
        padding-right: 5vw;
        }


`;


const UserWelcome = styled.div`
        color:white;
        text-align:left;
        margin-bottom:5vh;
        margin-top:5vh;
`


const Reminders = styled.div`
        display:flex;
        flex-direction:column;
        gap 1vh;
        width:100%;
`

const Reminder = styled.div`
        background-color:rgb(21,21,23);
        max-width:100%;
        height:100%;
        border-radius:10px;
        display:flex;
        flex-direction:column;
        align-items:left;
        justify-content:space-around;
        padding:16px;
        gap:6px;

`
const Rtime = styled.p`
        color:rgb(161,161,162);
        font-size:12px;
        font-weight:300;
        padding:0;
        margin:0;
    
`



const Rtitle = styled.p`
        color:#fff;
        font-size:14px;
        font-weight:bold;
        padding:0;
        margin:0;
    
`

const Rsubtitle = styled.p`
        color:rgb(161,161,162);
        font-size:14px;
        font-weight:bold;
        padding:0;
        margin:0;
    
`
const Rindicators = styled.div`
        display:flex;
        flex-direction:row;
        align-items:flex-end;
        justify-content:space-between;

`



const Rtags = styled.div`
        display:flex;
        flex-direction:row;
        gap:6px;
`

const Rtag = styled.div`
        background-color:rgb(35,35,35);
        color:rgba(255,255,255, .4);
        border-radius:100px;
        font-size:10px;
        padding:4px;
        padding-left:8px;
        padding-right:8px;
`


const Randrew = styled.img`
        height: 36px;
        width:auto;
        margin-right:-10px;
        margin-bottom:-10px;
`






// Set the date we're counting down to
var downDate = new Date("Jan 5, 2024 15:37:25");
var countDownDate = new Date("Jan 5, 2024 15:37:25").getTime();

// Update the count down every 1 second
var x = setInterval(function() {

  // Get today's date and time
  var now = new Date().getTime();
    
  // Find the distance between now and the count down date
  var distance = countDownDate - now;
    
  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    
  // Output the result in an element with id="demo"
    document.getElementById("time").innerHTML = downDate + '<br/>' + days + " days " + hours + " hours "
  + minutes + "m " + seconds + "s ";
    
  // If the count down is over, write some text 
  if (distance < 0) {
    clearInterval(x);
    document.getElementById("time").innerHTML = "EXPIRED";
  }
}, 1000);











export default Demo;