import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import FadeIn from 'react-fade-in';


const Footer = (props) => {
    return (
        <Container>

                <Link to={'/'} style={{ color: 'inherit', textDecoration: 'inherit'}}>    
                <FooterItem>
                   Home
                </FooterItem>
                </Link>


                <Link to={'/Features'} style={{ color: 'inherit', textDecoration: 'inherit'}}>    
                <FooterItem>
                   Features
                </FooterItem>
                </Link>

                <Link to={'/Pricing'} style={{ color: 'inherit', textDecoration: 'inherit'}}>
                <FooterItem>
                    Pricing
                </FooterItem>
                </Link>

                <Link to={'/About'} style={{ color: 'inherit', textDecoration: 'inherit'}}>
                <FooterItem>
                    About
                </FooterItem>
                </Link>
               
                <Link to={'/Story'} style={{ color: 'inherit', textDecoration: 'inherit'}}>
                <FooterItem>
                    Story
                </FooterItem>
                </Link>
               
                <Link to={'/Contact'} style={{ color: 'inherit', textDecoration: 'inherit'}}>
                <FooterItem>
                    Contact
                </FooterItem>
                </Link>

                <FooterDisc>
                    Andrew is not a real elephant. More an anthropomorphic collection of pixels that hopes to bring to life a simple reminder app. Despite this, Andrew is my freind and he has saved my bacon more times than I care to mention.

                </FooterDisc>
        </Container>

)};





const Container = styled.div`
        max-width:452px;
        display:flex;
        margin-left:auto;
        margin-right:auto;

        justify-content:center;
        flex-direction:column;
        position:relative;  
        margin-top:5vh;
        margin-bottom:5vh;
        

 
@media (max-width: 768px) {

margin-left:5vw;
margin-right: 5vw;
}


 

`;




const FooterItem = styled.div`
        width:100%;
        padding-top:2vh;
        padding-bottom:2vh;
        border-bottom:1px solid rgba(0,0,0, .5);
        display:flex;
        align-items:left;

        a {
            color:#fff;
            text-decoration:none;
        }

`

const FooterDisc = styled.div`
        width:100%;
        padding-top:2vh;
        padding-bottom:2vh;
        font-size:12px;
        display:flex;
        align-items:left;
        text-align:left;

`

export default Footer;