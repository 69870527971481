import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import FadeIn from 'react-fade-in';


const Header = (props) => {
    return (
    
      <Container>
          <Wrap>
          <Link to={"/"} style={{ color: 'inherit', textDecoration: 'inherit'}}>  
          <LogoGroup>
              <Logo src="./images/aa2run.gif" />
              <LogoText>Andrew</LogoText>
          </LogoGroup>
          </Link>
      
          </Wrap>
      </Container>
      

  );
};

const Container = styled.div`
z-index:199;
display: flex;
  align-items:center;
  justify-content:space-around;
  flex-direction:row;
  height:44px;
  position:absolute;
  left:0;
  right:0;
  top:0;
  
  @media (max-width: 768px) {

    left:2.5vw;
    right: 2.5vw;
    }
`
const Wrap = styled.div`
width:452px;

`

const LogoGroup = styled.div`
display: flex;
  align-items:center;
  justify-content:flex-start;
  flex-direction:row;

`

const Logo = styled.img`
height:44px;
width:auto;
`


const LogoText = styled.div`
  color:#fff;
  font-weight:bold;
`






export default Header;
