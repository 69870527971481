import { useEffect } from 'react';
import { Link } from "react-router-dom";
import styled from "styled-components";
import FadeIn from 'react-fade-in';




const Home = (props) => {
    useEffect(() => {
        document.documentElement.style.setProperty('--backgroundcolor', '#FF0000')
        console.log('--backgroundcolor');
    }, []);
    return (
        <Container>


           
           <HeroImg />
           
            <WelcomeText>
                  <h1>My name is Andrew. <br/> As an Elephant I remember everything. Give me something to remember for you!</h1>
            </WelcomeText>

            <Link to={'/Demo'} style={{ color: 'inherit', textDecoration: 'inherit'}}>   
            <CTA>
                Let Go !
            </CTA>
            </Link>

            <AndrewSpin />    

        

        </Container>

)};





const Container = styled.div`
max-width:452px;
display:flex;
margin-left:auto;
margin-right:auto;

justify-content:center;
flex-direction:column;
position:relative;  
margin-top:0vh;
margin-bottom:0vh;


@media (max-width: 768px) {

margin-left:5vw;
margin-right: 5vw;
}


`;




const HeroImg = styled.div`
        max-width: 100%;
  
        height:50vh;
    background-color:#ff0000;
    background-image:url('./images/homeAguide.png');
    background-size:contain;
    background-position:bottom;
    background-repeat:no-repeat;

       

        @media (max-width: 768px) {
            max-width: calc(100vw + 5vw);
            max-height:100%;
            margin-left:-5vw;
            margin-right: -5vw;
            margin-top:-46px;
              box-shadow: -2px -58px 36px 0px rgba(255,0,0,1) inset;
             -webkit-box-shadow: -2px -58px 36px 0px rgba(255,0,0,1) inset;
            -moz-box-shadow: -2px -58px 36px 0px rgba(255,0,0,1) inset;
            }
            

        
        

`



const AndrewSpin = styled.div`
        max-width: 100%;
  
        height:50vh;
    background-color:#ff0000;
    background-image:url('./images/aspin2.gif');
    background-size:contain;
    background-position:bottom;
    background-repeat:no-repeat;

       

        @media (max-width: 768px) {
            max-width: calc(100vw + 5vw);
            max-height:100%;
            margin-left:-5vw;
            margin-right: -5vw;
            margin-top:-46px;
              box-shadow: -2px -58px 36px 0px rgba(255,0,0,1) inset;
             -webkit-box-shadow: -2px -58px 36px 0px rgba(255,0,0,1) inset;
            -moz-box-shadow: -2px -58px 36px 0px rgba(255,0,0,1) inset;
            }
            

        
        

`



const CTA = styled.div`
    background-color:rgb(255,198,8);
    max-width:100%;
    color:#fff;
    font-weight:bold;
    padding:20px;
    border-radius:4px;
    position:relative;

    @media (max-width: 768px) {
        position:fixed;
        bottom:20px;
        left:20px;
        right:20px;
    }
`

const Ahello = styled.div`
width:100%;
display:flex;
margin-left:auto;
margin-right:auto;

justify-content:flex-start;
flex-direction:row;
position:relative;  
margin-bottom:1vh;
margin-top:10vh;
gap:20px;

`

const AndrewWelcome = styled.img`
        max-width: 60px;
        max-height:60px;

`



const AndrewText = styled.div`
    color:white;
    width: 95vw;
    text-align:left;
    line-height:60px;
    font-size:32px;
    font-weight:bold;

`

const WelcomeText = styled.div`
    color:white;
    text-align:left;
    margin-bottom:5vh;
    margin-top:5vh;
`




export default Home;




//  box-shadow: -2px -58px 36px 0px rgba(255,0,0,1) inset;
// -webkit-box-shadow: -2px -58px 36px 0px rgba(255,0,0,1) inset;
// -moz-box-shadow: -2px -58px 36px 0px rgba(255,0,0,1) inset;