import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Features from './components/Features';
import Pricing from './components/Pricing';
import About from './components/About';
import Demo from './components/Demo';
import Story from './components/Story';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { useEffect } from 'react';


import "./App.css";
import styled from "styled-components";

function App() {
  useEffect(() => {
    document.documentElement.style.setProperty('--backgroundcolor', '#FF0000');
    console.log('--backgroundcolor');
}, []);
  return (

    
    
    <div className="App">

      <Router>
           <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/Features" element={<Features />} />
                    <Route path="/Pricing" element={<Pricing />} />
                    <Route path="/About" element={<About />} />
                    <Route path="/Demo" element={<Demo />} />
                    <Route path="/Story" element={<Story />} />
                    <Route path="/Contact" element={<Contact />} />
                </Routes>
            <Footer />
       </Router>
     
    </div>
  );
}





export default App;
