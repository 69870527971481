import { useEffect } from 'react';
import { Link } from "react-router-dom";
import styled from "styled-components";
import FadeIn from 'react-fade-in';


const About = (props) => {
    useEffect(() => {
        document.documentElement.style.setProperty('--backgroundcolor', 'rgb(37,37,37)');
        console.log('--backgroundcolor');
    }, []);
    return (
        <Container>
       
            <WelcomeText>
                  <h1>Never Forget.<br/>
                  Anything.<br/>
                  Ever.</h1>
                 <h2>Everyone has something they don't want to forget. A deadline they should never miss. Some hugely important repeating deadline that needs to adhere to...</h2>
                <p>This is some more text to describe whats going one.This is some more text to describe whats going one.This is some more text to describe whats going one.This is some more text to describe whats going one.This is some more text to describe whats going one.This is some more text to describe whats going one.</p>
           
            </WelcomeText>

            
        </Container>

)};





const Container = styled.div`
max-width:452px;

display:flex;
margin-left:auto;
margin-right:auto;

justify-content:center;
flex-direction:column;
position:relative;  
margin-top:0vh;
margin-bottom:0vh;



@media (max-width: 768px) {

padding-left:5vw;
padding-right: 5vw;
}


`;


const Ahello = styled.div`
width:100%;
display:flex;
margin-left:auto;
margin-right:auto;

justify-content:flex-start;
flex-direction:row;
position:relative;  
margin-bottom:1vh;
margin-top:10vh;
gap:20px;

`

const AndrewWelcome = styled.img`
        max-width: 60px;
        max-height:60px;

`



const AndrewText = styled.div`
    color:white;
    width: 95vw;
    text-align:left;
    line-height:60px;
    font-size:32px;
    font-weight:bold;

`

const WelcomeText = styled.div`
    color:white;
    text-align:left;
    margin-top:10vh;
    margin-bottom:10vh;
`




export default About;