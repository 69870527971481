import { useEffect } from 'react';
import { Link } from "react-router-dom";
import styled from "styled-components";
import FadeIn from 'react-fade-in';


const Features = (props) => {
    useEffect(() => {
        document.documentElement.style.setProperty('--backgroundcolor', 'rgb(0,109,192)');
        console.log('--backgroundcolor');
    }, []);
    return (
       
        <Container>
             <FadeIn>
            <Ahello>
            <AndrewWelcome src="./images/bigelephant.gif" />
            <AndrewText>Hello!</AndrewText>

            </Ahello>
            <WelcomeText>
                  <h1>My name is Andrew. <br/> As a Elephant I remember everything. Give me something to remember for you!</h1>
            </WelcomeText>

            </FadeIn>
        </Container>
        

)};





const Container = styled.div`
max-width:452px;

display:flex;
margin-left:auto;
margin-right:auto;

justify-content:center;
flex-direction:column;
position:relative;  
margin-top:0vh;
margin-bottom:0vh;
background-color:rgb(0,109,192);


@media (max-width: 768px) {

padding-left:5vw;
padding-right: 5vw;
}


`;


const Ahello = styled.div`
width:100%;
display:flex;
margin-left:auto;
margin-right:auto;

justify-content:flex-start;
flex-direction:row;
position:relative;  
margin-bottom:1vh;
margin-top:10vh;
gap:20px;

`

const AndrewWelcome = styled.img`
        max-width: 60px;
        max-height:60px;

`



const AndrewText = styled.div`
    color:white;
    width: 95vw;
    text-align:left;
    line-height:60px;
    font-size:32px;
    font-weight:bold;

`

const WelcomeText = styled.div`
    color:white;
    text-align:left;

    margin-bottom:10vh;
`




export default Features;